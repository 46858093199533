import { createNodeFromContent, DOMNode, Node, wrappingInputRule } from '@tiptap/core';
import { Fragment } from '@tiptap/pm/model';
import { CALLOUT_TYPE_TO_ICON, CALLOUT_TYPE_TO_LABEL, CalloutType } from './callout.model';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    callout: {
      setCallout: (type: CalloutType) => ReturnType;
    };
  }
}

const calloutClassName = 'callout';

export const Callout = Node.create({
  name: 'callout',
  content: 'paragraph+',
  group: 'block',
  defining: true,
  selectable: true,
  draggable: true,

  addAttributes() {
    return {
      class: {
        renderHTML: (attributes) => {
          const otherClasses = ((attributes['class'] ?? '') as string).split(' ').filter((c) => c !== calloutClassName);
          return { class: `${calloutClassName} ${otherClasses.join(' ')}` };
        },
      },
      type: {},
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.${calloutClassName}`,
        getContent(node: DOMNode, schema) {
          if (!(node instanceof Element)) {
            return Fragment.from(createNodeFromContent(node.textContent, schema));
          }

          const calloutContentElement = node.querySelector('.callout-content');

          const htmlContent = calloutContentElement ? calloutContentElement.outerHTML : node.innerHTML;

          return Fragment.from(createNodeFromContent(htmlContent, schema));
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const type = HTMLAttributes['type'] as CalloutType | undefined;
    const iconPath = type ? CALLOUT_TYPE_TO_ICON[type] ?? '' : '';
    const label = type ? CALLOUT_TYPE_TO_LABEL[type] ?? '' : '';

    const headerElements = [
      iconPath ? ['img', { src: iconPath, class: 'callout-icon not-prose', alt: `${label} icon` }] : null,
      label ? ['span', { class: 'callout-label not-prose' }, label] : null,
    ].filter(Boolean);

    return [
      'div',
      HTMLAttributes,
      ['div', { class: 'callout-header not-prose' }, ...headerElements],
      ['div', { class: 'callout-content not-prose' }, 0],
    ];
  },

  addInputRules() {
    return [
      wrappingInputRule({
        find: /^\/callout$/,
        type: this.type,
      }),
    ];
  },

  addCommands() {
    return {
      setCallout:
        (type: CalloutType) =>
        ({ commands }) => {
          return commands.toggleWrap(this.name, { type });
        },
    };
  },
});
