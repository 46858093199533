<dojo-page-layout
  [wrapInsideIonContent]="false"
  [isShowBlurTopPanel]="true"
  [showHeader]="true"
  class="topic-detail-view-page"
>
  @if (state.isMobile) {
    <ion-header ion-header-content [translucent]="true" class="mobile-header">
      <ion-toolbar class="!px-0">
        <div class="small:mx-auto flex items-center justify-between px-4">
          <div slot="start" class="flex">
            <dojo-icon-button
              (click)="navigateToTopicOverview()"
              [icon]="'arrow-left'"
              [iconSize]="18"
              [iconClass]="'text-neutral-500'"
            />
          </div>
          <ion-title class="common-small-ion-title">{{ state.topic?.code }}</ion-title>
          <div slot="end" class="w-8"></div>
        </div>
      </ion-toolbar>
    </ion-header>
  }
  <div main-content>
    @if (!state.isLoadingPageVersions && state.topic) {
      <div class="main-content-header" @appearDisappear>
        @if (!state.isMobile) {
          <div class="back-button mb-4">
            <dojo-button fill="clear" class="text-neutral-300" [customWidth]="70" (click)="navigateToTopicOverview()">
              <dojo-icon [icon]="'arrow-left'" class="mr-1" [size]="'18'" />
              {{ 'back' | transloco }}
            </dojo-button>
          </div>
          <dojo-topic-breadcrumbs class="topic-breadcrumbs" />
        }
        <div
          class="mb-4 flex h-[48px] w-[48px] items-center justify-center rounded-full md:h-[56px] md:w-[56px]"
          [ngClass]="['bg-dynamic-' + state.topic.topicLevel1?.color_name + '-400']"
          [class.mt-8]="!state.isMobile"
        >
          <dojo-icon
            [icon]="state.topic.icon!"
            [size]="state.isMobile ? 18 : 30"
            [alt]="state.topic.code"
            [ngClass]="['text-dynamic-' + state.topic.topicLevel1?.color_name + '-50']"
            [faPrefix]="'fas'"
          />
        </div>
        <div class="text-primary-exam mb-2 text-2xl font-semibold">
          @if (!state.isMobile) {
            {{ state.topic.code }}
          }
          <dojo-localized-string [content]="state.topic.name" />
        </div>
        <div class="text-primary-exam mb-6 text-lg">
          @if (state.topic.description | localize | async; as content) {
            <dojo-markdown-viewer [content]="content" />
          }
        </div>

        <!-- TODO uncomment this when we have the tabs and delete the divider div -->
        <div class="w-full" style="border-bottom: 1px solid var(--neutral-200)"></div>
        <div class="custom-mat-tab" [hidden]="true">
          <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false">
            @for (tab of tabs; track tab) {
              <a mat-tab-link [routerLink]="tab" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
                {{ 'examdojo.learn_hub.topic_tabs.' + tab | transloco }}
              </a>
            }
          </nav>
        </div>
      </div>

      <mat-tab-nav-panel #tabPanel class="main-content-area" @appearDisappear>
        <div class="py-6">
          <router-outlet></router-outlet>
        </div>
      </mat-tab-nav-panel>
    } @else {
      <div class="flex h-[calc(100vh-theme('spacing.16'))] w-full items-center justify-center">
        <dojo-loader />
      </div>
    }
  </div>

  <div sidebar-content class="overflow-hidden">
    @if (!!state.activeTiptapEditor) {
      <div class="table-contents sidebar-content !px-3">
        <div class="font-medium-exam text-primary-exam mb-2 px-4 text-base">
          {{ 'learn_hub.on_this_page' | transloco }}
        </div>
        <dojo-tiptap-table-of-contents
          [editor]="state.activeTiptapEditor.editor!"
          [tableOfContentData]="state.activeTiptapEditor.tableOfContentData()"
          [scrollableParent]="state.scrollableContainer"
        />
      </div>
    }
  </div>
</dojo-page-layout>
