import { AsyncPipe, NgClass } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTabLink, MatTabNav, MatTabNavPanel } from '@angular/material/tabs';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { getAnimationAppearDisappear } from '@examdojo/animation';
import { PageVersionService, TopicLevel2Query, TopicLevel2UIModel } from '@examdojo/category/v2';
import { LocalizedStringComponent, LocalizePipe } from '@examdojo/core/i18n';
import { IconComponent } from '@examdojo/core/icon';
import { TiptapEditorService, TiptapTableOfContentsComponent } from '@examdojo/core/tiptap';
import { PlatformService } from '@examdojo/platform';
import { ButtonComponent } from '@examdojo/ui/button';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { LoaderComponent } from '@examdojo/ui/loader';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { IonHeader, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { BehaviorSubject, distinctUntilKeyChanged, filter, finalize, Observable, switchMap } from 'rxjs';
import { RootUrlParts } from '../../../app.model';
import { PageLayoutComponent } from '../../../shared/page-layout/page-layout.component';
import { TopicLevel2Tab } from '../learn-hub.routes';
import { TopicBreadcrumbsComponent } from './topic-breadcrumbs.component';

@Component({
  selector: 'dojo-topic-level-2-detail-view',
  imports: [
    TranslocoPipe,
    MatTabNav,
    MatTabLink,
    MatTabNavPanel,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    TopicBreadcrumbsComponent,
    LocalizedStringComponent,
    PageLayoutComponent,
    TiptapTableOfContentsComponent,
    IconComponent,
    NgClass,
    LocalizePipe,
    AsyncPipe,
    MarkdownViewerComponent,
    LoaderComponent,
    ButtonComponent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IconButtonComponent,
  ],
  animations: [getAnimationAppearDisappear()],
  templateUrl: './topic-level-2-detail-view.component.html',
  styleUrl: './topic-level-2-detail-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'w-full' },
  providers: [TiptapEditorService],
})
export class TopicLevel2DetailViewComponent implements AfterViewInit {
  constructor(
    private readonly topicLevel2Query: TopicLevel2Query,
    private readonly pageVersionService: PageVersionService,
    private readonly elementRef: ElementRef,
    private readonly tiptapEditorService: TiptapEditorService,
    private readonly router: Router,
    private readonly platformService: PlatformService,
  ) {
    this.fetchPageVersionsOnTopicLevel2Change().pipe(takeUntilDestroyed()).subscribe();
  }

  private readonly topic$: Observable<TopicLevel2UIModel | null> = this.topicLevel2Query.active$;
  private readonly isLoadingPageVersions$ = new BehaviorSubject<boolean>(false);

  readonly tabs = Object.values(TopicLevel2Tab);

  readonly state = connectState({
    topic: this.topic$,
    activeTiptapEditor: this.tiptapEditorService.editor$,
    scrollableContainer: this.tiptapEditorService.scrollingContainer$,
    isLoadingPageVersions: this.isLoadingPageVersions$,
    isMobile: this.platformService.isSmall$,
  });

  ngAfterViewInit() {
    const scrollableElement = this.elementRef.nativeElement.closest('.main-content-area') as HTMLElement;
    this.tiptapEditorService.registerScrollingContainer(scrollableElement);
  }

  navigateToTopicOverview() {
    this.router.navigate([RootUrlParts.LearnHub]);
  }

  private fetchPageVersionsOnTopicLevel2Change() {
    return this.topic$.pipe(
      filter(Boolean),
      distinctUntilKeyChanged('id'),
      switchMap((topicLevel2) => {
        this.isLoadingPageVersions$.next(true);
        return this.pageVersionService
          .fetchForTopicLevel2(topicLevel2.id)
          .pipe(finalize(() => this.isLoadingPageVersions$.next(false)));
      }),
    );
  }
}
