import { ChangeDetectionStrategy, Component, input, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { connectState } from '@examdojo/angular/util';
import { PageType, PageVersionQuery } from '@examdojo/category/v2';
import { DateTimeModule } from '@examdojo/core/date-time';
import { EmptyStateComponent } from '@examdojo/core/empty-state';
import { TiptapEditorComponent, TiptapEditorService } from '@examdojo/core/tiptap';
import { TranslocoPipe } from '@jsverse/transloco';
import { filter, map, merge, startWith, switchMap, tap } from 'rxjs';

@Component({
  selector: 'dojo-topic-concept-tab',
  imports: [TiptapEditorComponent, DateTimeModule, EmptyStateComponent, TranslocoPipe],
  templateUrl: './topic-tab.component.html',
  styleUrl: './topic-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
  animations: [],
})
export class TopicTabComponent {
  constructor(
    private readonly pageVersionQuery: PageVersionQuery,
    private readonly tiptapEditorService: TiptapEditorService,
  ) {
    merge(
      this.activePageVersion$.pipe(
        map((page) => page?.content),
        tap((content) => {
          this.contentCtrl.setValue(content ?? '');
        }),
      ),
      this.tiptapEditor$.pipe(
        filter(Boolean),
        tap((tiptapEditor) => {
          this.tiptapEditorService.registerTiptapEditor(tiptapEditor);
        }),
        takeUntilDestroyed(),
      ),
    )
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  readonly tiptapEditor = viewChild<TiptapEditorComponent>('tiptapEditor');
  readonly tiptapEditor$ = toObservable(this.tiptapEditor);

  readonly scrollableContainer = signal<HTMLElement | undefined>(undefined);

  readonly pageType = input.required<PageType>();

  readonly activePageVersion$ = toObservable(this.pageType).pipe(
    switchMap((pageType) => this.pageVersionQuery.getPublishedVersionForType(pageType)),
    startWith(undefined),
  );

  readonly contentCtrl = new FormControl<string>(``, { nonNullable: true });

  readonly state = connectState({
    activePageVersion: this.activePageVersion$,
    scrollableContainer: this.tiptapEditorService.scrollingContainer$,
  });
}
