import { ChangeDetectionStrategy, Component } from '@angular/core';
import { connectState } from '@examdojo/angular/util';
import { TopicLevel1Query, TopicLevel2Query } from '@examdojo/category/v2';
import { LocalizedStringComponent } from '@examdojo/core/i18n';
import { IconComponent } from '@examdojo/core/icon';

@Component({
  selector: 'dojo-topic-breadcrumbs',
  imports: [IconComponent, LocalizedStringComponent],
  template: `
    @if (state.topicLevel1) {
      <div class="flex items-start gap-2 text-sm text-neutral-400">
        <dojo-localized-string style="min-width: fit-content" [content]="state.topicLevel1.name" />
        @if (state.topicLevel2) {
          <dojo-icon icon="chevron-right" style="margin-top: 3px" />
          <dojo-localized-string [content]="state.topicLevel2.name" />
        }
      </div>
    }
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopicBreadcrumbsComponent {
  constructor(
    private readonly topicLevel1Query: TopicLevel1Query,
    private readonly topicLevel2Query: TopicLevel2Query,
  ) {}

  readonly state = connectState({
    topicLevel1: this.topicLevel1Query.active$,
    topicLevel2: this.topicLevel2Query.active$,
  });
}
