@if (state.activePageVersion; as version) {
  <dojo-tiptap-editor
    [formCtrl]="contentCtrl"
    [editable]="false"
    [scrollableParent]="state.scrollableContainer"
    #tiptapEditor
    class="dojo-app-editor"
  />
} @else {
  @if (state.activePageVersion === null) {
    <dojo-empty-state [body]="'examdojo.no_data' | transloco" />
  }
}
