@if (editable()) {
  <div class="z-1 sticky top-0 flex items-center gap-1 rounded border border-gray-300 bg-white text-gray-300">
    @for (type of calloutTypes; track type) {
      <button
        type="button"
        class="p-1"
        (click)="editor.chain().focus().setCallout(type).run()"
        [ngClass]="{ 'bg-gray-900 text-white': editor.isActive('callout', { type: type }) }"
      >
        <img src="{{ CALLOUT_TYPE_TO_ICON[type] }}" alt="{{ CALLOUT_TYPE_TO_LABEL[type] }}" class="mr-1 inline-flex" />
        <span>{{ CALLOUT_TYPE_TO_LABEL[type] }}</span>
      </button>
    }
  </div>
}

<tiptap-editor [editor]="editor" [formControl]="formCtrl()" [class.editable]="editable()" class="prose"></tiptap-editor>

<tiptap-bubble-menu [editor]="editor">
  <div class="flex items-center gap-1 divide-x rounded border border-black bg-black p-1 text-gray-300">
    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().toggleBold().run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive('bold') }"
    >
      <dojo-icon icon="bold" />
    </button>
    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().toggleItalic().run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive('italic') }"
    >
      <dojo-icon icon="italic" />
    </button>
    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().toggleStrike().run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive('strike') }"
    >
      <dojo-icon icon="strikethrough" />
    </button>

    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().setTextAlign('left').run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive({ textAlign: 'left' }) }"
    >
      <dojo-icon icon="align-left" />
    </button>
    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().setTextAlign('center').run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive({ textAlign: 'center' }) }"
    >
      <dojo-icon icon="align-center" />
    </button>
    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().setTextAlign('right').run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive({ textAlign: 'right' }) }"
    >
      <dojo-icon icon="align-right" />
    </button>

    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().toggleBulletList().run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive('strike') }"
    >
      <dojo-icon icon="list-ul" />
    </button>

    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().toggleOrderedList().run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive('strike') }"
    >
      <dojo-icon icon="list-ol" />
    </button>
  </div>
</tiptap-bubble-menu>
