@for (item of tableOfContentData(); track item.id; let idx = $index) {
  <a
    class="item text-secondary rounded py-1.5 hover:bg-sky-50"
    [style.--level]="item.level"
    [ngClass]="{ 'is-active': item.isActive, 'is-scrolled-over': item.isScrolledOver }"
    (click)="onItemClick(item.id)"
    [attr.data-toc-id]="item.id"
    [attr.data-item-index]="item.itemIndex"
  >
    <dojo-markdown-viewer [content]="item.textContent" class="latex-sample" />
  </a>
}
