import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, input, signal, viewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from '@examdojo/core/icon';
import { Mathematics } from '@tiptap-pro/extension-mathematics';
import { getHierarchicalIndexes, TableOfContentData, TableOfContents } from '@tiptap-pro/extension-table-of-contents';
import { Editor, nodePasteRule } from '@tiptap/core';
import { Image } from '@tiptap/extension-image';
import { Link } from '@tiptap/extension-link';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Table } from '@tiptap/extension-table';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import { TextAlign } from '@tiptap/extension-text-align';
import { Youtube } from '@tiptap/extension-youtube';
import { StarterKit } from '@tiptap/starter-kit';
import { TiptapBubbleMenuDirective, TiptapEditorDirective } from 'ngx-tiptap';
import { Callout, CALLOUT_TYPE_TO_ICON, CALLOUT_TYPE_TO_LABEL, CalloutType } from './extensions';

@Component({
  selector: 'dojo-tiptap-editor',
  imports: [TiptapEditorDirective, TiptapBubbleMenuDirective, ReactiveFormsModule, NgClass, FormsModule, IconComponent],
  templateUrl: './tiptap-editor.component.html',
  styleUrl: './tiptap-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'tipTapEditor',
})
export class TiptapEditorComponent {
  constructor() {
    effect(() => {
      this.editor.setEditable(this.editable());
    });
  }

  readonly formCtrl = input.required<FormControl<string>>();
  readonly editable = input(true);
  readonly scrollableParent = input<HTMLElement>();

  readonly tiptapEditorDirective = viewChild(TiptapEditorDirective);

  readonly CALLOUT_TYPE_TO_LABEL = CALLOUT_TYPE_TO_LABEL;

  readonly calloutTypes = Object.values(CalloutType);

  readonly tableOfContentData = signal<TableOfContentData>([]);

  readonly editor = new Editor({
    autofocus: false,
    extensions: [
      StarterKit.configure({
        paragraph: false, // disable default paragraph
      }),
      TableOfContents.configure({
        getIndex: getHierarchicalIndexes,
        onUpdate: (content) => {
          this.tableOfContentData.set(content);
        },
        scrollParent: (): HTMLElement | Window => this.scrollableParent() ?? this.editor.view.dom,
      }),
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableCell,
      TableHeader,
      Paragraph.extend({
        addAttributes() {
          return {
            class: {
              // Keep existing classes
              default: null,
            },
          };
        },
      }),
      Mathematics.configure({
        regex: /\$\$([^$]*)\$\$/gi,
        katexOptions: {
          displayMode: false,
        },
      }),
      Link,
      Image.extend({
        addPasteRules() {
          return [
            nodePasteRule({
              find: /^(https?:\/\/)?[a-z0-9-]+(\.[a-z0-9-]+)+([/\w-]+)*\/[\w-]+\.(jpg|jpeg|gif|png|webp|svg)(\?[\w=&-]*)?$/g,
              type: this.type,
              getAttributes: (match) => {
                return { src: match.input };
              },
            }),
          ];
        },
      }),
      Youtube,
      Callout,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
  });

  protected readonly CALLOUT_TYPE_TO_ICON = CALLOUT_TYPE_TO_ICON;
}
