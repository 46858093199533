import { Extension as t } from "@tiptap/core";
import { v4 as e } from "uuid";
import { Plugin as o, PluginKey as n } from "@tiptap/pm/state";
const s = ({
    getId: t,
    anchorTypes: s = ["heading"]
  }) => new o({
    key: new n("tableOfContent"),
    appendTransaction(o, n, i) {
      const r = i.tr;
      let l = !1;
      if (o.some(t => t.docChanged)) {
        const o = [];
        i.doc.descendants((n, i) => {
          const d = n.attrs["data-toc-id"];
          if (s.includes(n.type.name) && 0 !== n.textContent.length) {
            if (null == d || o.includes(d)) {
              let o = "";
              o = t ? t(n.textContent) : e(), r.setNodeMarkup(i, void 0, {
                ...n.attrs,
                "data-toc-id": o,
                id: o
              }), l = !0;
            }
            o.push(d);
          }
        });
      }
      return l ? r : null;
    }
  }),
  i = (t, e) => {
    let o = t.filter(t => t.level === e).pop();
    if (0 !== e) return o || (o = i(t, e - 1)), o;
  },
  r = (t, e) => {
    let o = 1;
    const n = e.at(-1),
      s = [...e].reverse().find(e => e.originalLevel <= t.node.attrs.level),
      i = (null == s ? void 0 : s.level) || 1;
    return o = t.node.attrs.level > ((null == n ? void 0 : n.originalLevel) || 1) ? ((null == n ? void 0 : n.level) || 1) + 1 : t.node.attrs.level < ((null == n ? void 0 : n.originalLevel) || 1) ? i : (null == n ? void 0 : n.level) || 1, o;
  },
  l = (t, e) => {
    const o = e.at(-1);
    return o ? ((null == o ? void 0 : o.itemIndex) || 1) + 1 : 1;
  },
  d = (t, e, o) => {
    var n, s;
    const i = o || t.node.attrs.level || 1;
    let r = 1;
    const l = e.filter(t => t.level <= i);
    return r = (null === (n = l.at(-1)) || void 0 === n ? void 0 : n.level) === i ? ((null === (s = l.at(-1)) || void 0 === s ? void 0 : s.itemIndex) || 1) + 1 : 1, r;
  };
function a(t, e) {
  let o = null;
  return (...n) => {
    o && clearTimeout(o), o = setTimeout(() => {
      t(...n);
    }, e);
  };
}
const c = (t, e) => {
    const {
        editor: o
      } = e,
      n = [],
      s = [];
    let i = null;
    if (o.isDestroyed) return t;
    if (o.state.doc.descendants((t, o) => {
      var s;
      (null === (s = e.anchorTypes) || void 0 === s ? void 0 : s.includes(t.type.name)) && n.push({
        node: t,
        pos: o
      });
    }), n.forEach(t => {
      const n = o.view.domAtPos(t.pos + 1).node;
      e.storage.scrollPosition >= n.offsetTop && (i = t.node.attrs["data-toc-id"], s.push(t.node.attrs["data-toc-id"]));
    }), t = t.map(t => ({
      ...t,
      isActive: t.id === i,
      isScrolledOver: s.includes(t.id)
    })), e.onUpdate) {
      const o = 0 === e.storage.content.length;
      e.onUpdate(t, o);
    }
    return t;
  },
  p = t => {
    const {
      editor: e,
      onUpdate: o
    } = t;
    if (e.isDestroyed) return;
    const n = [];
    let s = [];
    const i = [];
    if (e.state.doc.descendants((e, o) => {
      var s;
      (null === (s = t.anchorTypes) || void 0 === s ? void 0 : s.includes(e.type.name)) && n.push({
        node: e,
        pos: o
      });
    }), n.forEach((o, r) => {
      if (0 === o.node.textContent.length) return;
      const l = e.view.domAtPos(o.pos + 1).node,
        d = t.storage.scrollPosition >= l.offsetTop;
      i.push(l);
      const a = o.node.attrs.level,
        c = n[r - 1],
        p = t.getLevelFn(o, s),
        h = t.getIndexFn(o, s, p);
      s = c ? [...s, {
        itemIndex: h,
        id: o.node.attrs["data-toc-id"],
        originalLevel: a,
        level: p,
        textContent: o.node.textContent,
        pos: o.pos,
        editor: e,
        isActive: !1,
        isScrolledOver: !1,
        node: o.node,
        dom: l
      }] : [...s, {
        itemIndex: h,
        id: o.node.attrs["data-toc-id"],
        originalLevel: a,
        level: p,
        textContent: o.node.textContent,
        pos: o.pos,
        editor: e,
        isActive: !1,
        isScrolledOver: d,
        node: o.node,
        dom: l
      }];
    }), s = c(s, t), o) {
      const e = 0 === t.storage.content.length;
      o(s, e);
    }
    t.storage.anchors = i, t.storage.content = s, e.state.tr.setMeta("toc", s), e.view.dispatch(e.state.tr);
  },
  h = t.create({
    name: "tableOfContents",
    addStorage: () => ({
      content: [],
      anchors: [],
      scrollHandler: () => null,
      scrollPosition: 0
    }),
    addGlobalAttributes() {
      return [{
        types: this.options.anchorTypes || ["headline"],
        attributes: {
          id: {
            default: null,
            renderHTML: t => ({
              id: t.id
            }),
            parseHTML: t => t.id || null
          },
          "data-toc-id": {
            default: null,
            renderHTML: t => ({
              "data-toc-id": t["data-toc-id"]
            }),
            parseHTML: t => t.dataset.tocId || null
          }
        }
      }];
    },
    addOptions: () => ({
      onUpdate: () => {},
      getId: t => e(),
      scrollParent: "undefined" != typeof window ? () => window : void 0,
      anchorTypes: ["heading"]
    }),
    addCommands() {
      return {
        updateTableOfContents: () => ({
          dispatch: t
        }) => {
          var e;
          return t && p({
            editor: this.editor,
            storage: this.storage,
            onUpdate: null === (e = this.options.onUpdate) || void 0 === e ? void 0 : e.bind(this),
            getIndexFn: this.options.getIndex || l,
            getLevelFn: this.options.getLevel || r,
            anchorTypes: this.options.anchorTypes
          }), !0;
        }
      };
    },
    onTransaction({
      transaction: t
    }) {
      var e;
      t.docChanged && !t.getMeta("toc") && p({
        editor: this.editor,
        storage: this.storage,
        onUpdate: null === (e = this.options.onUpdate) || void 0 === e ? void 0 : e.bind(this),
        getIndexFn: this.options.getIndex || l,
        getLevelFn: this.options.getLevel || r,
        anchorTypes: this.options.anchorTypes
      });
    },
    onCreate() {
      var t;
      const {
          tr: o
        } = this.editor.state,
        n = [];
      if (this.options.scrollParent && "function" != typeof this.options.scrollParent && console.warn("[Tiptap Table of Contents Deprecation Notice]: The 'scrollParent' option must now be provided as a callback function that returns the 'scrollParent' element. The ability to pass this option directly will be deprecated in future releases."), this.editor.state.doc.descendants((t, s) => {
        var i;
        const r = t.attrs["data-toc-id"];
        if ((null === (i = this.options.anchorTypes) || void 0 === i ? void 0 : i.includes(t.type.name)) && 0 !== t.textContent.length) {
          if (null == r || n.includes(r)) {
            let n = "";
            n = this.options.getId ? this.options.getId(t.textContent) : e(), o.setNodeMarkup(s, void 0, {
              ...t.attrs,
              "data-toc-id": n,
              id: n
            });
          }
          n.push(r);
        }
      }), this.editor.view.dispatch(o), p({
        editor: this.editor,
        storage: this.storage,
        onUpdate: null === (t = this.options.onUpdate) || void 0 === t ? void 0 : t.bind(this),
        getIndexFn: this.options.getIndex || l,
        getLevelFn: this.options.getLevel || r,
        anchorTypes: this.options.anchorTypes
      }), this.storage.scrollHandler = () => {
        var t;
        if (!this.options.scrollParent) return;
        const e = "function" == typeof this.options.scrollParent ? this.options.scrollParent() : this.options.scrollParent,
          o = e instanceof HTMLElement ? e.scrollTop : e.scrollY;
        this.storage.scrollPosition = o || 0;
        const n = c(this.storage.content, {
          editor: this.editor,
          anchorTypes: this.options.anchorTypes,
          storage: this.storage,
          onUpdate: null === (t = this.options.onUpdate) || void 0 === t ? void 0 : t.bind(this)
        });
        this.storage.content = n;
      }, !this.options.scrollParent) return;
      const s = "function" == typeof this.options.scrollParent ? this.options.scrollParent() : this.options.scrollParent;
      s && s.addEventListener("scroll", this.storage.scrollHandler);
    },
    onDestroy() {
      if (!this.options.scrollParent) return;
      const t = "function" == typeof this.options.scrollParent ? this.options.scrollParent() : this.options.scrollParent;
      t && t.removeEventListener("scroll", this.storage.scrollHandler);
    },
    addProseMirrorPlugins() {
      return [s({
        getId: this.options.getId,
        anchorTypes: this.options.anchorTypes
      })];
    }
  });
export { h as TableOfContents, s as TableOfContentsPlugin, a as debounce, h as default, r as getHeadlineLevel, d as getHierarchicalIndexes, i as getLastHeadingOnLevel, l as getLinearIndexes };