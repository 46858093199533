import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TiptapEditorComponent } from './tiptap-editor.component';

@Injectable()
export class TiptapEditorService {
  private readonly editor$$ = new BehaviorSubject<TiptapEditorComponent | undefined>(undefined);
  readonly editor$ = this.editor$$.asObservable();

  private readonly scrollingContainer$$ = new BehaviorSubject<HTMLElement | undefined>(undefined);
  readonly scrollingContainer$ = this.scrollingContainer$$.asObservable();

  registerTiptapEditor(editor: TiptapEditorComponent) {
    this.editor$$.next(editor);
  }

  registerScrollingContainer(element: HTMLElement) {
    this.scrollingContainer$$.next(element);
  }
}
