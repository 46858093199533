import { getChangedRanges as t, Extension as e } from "@tiptap/core";
import { Plugin as i, PluginKey as o } from "@tiptap/pm/state";
import { DecorationSet as n, Decoration as a } from "@tiptap/pm/view";
import d from "katex";
const r = e => {
    const {
      regex: r,
      katexOptions: s = {},
      editor: c,
      shouldRender: p
    } = e;
    return new i({
      key: new o("mathematics"),
      state: {
        init: () => ({
          decorations: void 0,
          isEditable: void 0
        }),
        apply(e, i, o, m) {
          if (!e.docChanged && !e.selectionSet && i.decorations) return i;
          const l = (i.decorations || n.empty).map(e.mapping, e.doc),
            {
              selection: h
            } = m,
            f = c.isEditable,
            g = [],
            {
              minFrom: u,
              maxTo: x
            } = function (e, i, o, n, a) {
              const d = e.doc.nodeSize - 2;
              let r = 0,
                s = d;
              if (i.isEditable !== o) r = 0, s = d;else if (n.docChanged) r = d, s = 0, t(n).forEach(t => {
                r = Math.min(r, t.newRange.from - 1, t.oldRange.from - 1), s = Math.max(s, t.newRange.to + 1, t.oldRange.to + 1);
              });else if (n.selectionSet) {
                const {
                    $from: t,
                    $to: i
                  } = a.selection,
                  {
                    $from: o,
                    $to: n
                  } = e.selection;
                r = Math.min(0 === t.depth ? 0 : t.before(), 0 === o.depth ? 0 : o.before()), s = Math.max(0 === i.depth ? s : i.after(), 0 === n.depth ? s : n.after());
              }
              return {
                minFrom: Math.max(r, 0),
                maxTo: Math.min(s, d)
              };
            }(m, i, f, e, o);
          m.doc.nodesBetween(u, x, (t, e) => {
            const i = p(m, e, t);
            if (t.isText && t.text && i) {
              let i;
              for (; i = r.exec(t.text);) {
                const t = e + i.index,
                  o = t + i[0].length,
                  n = i.slice(1).find(Boolean);
                if (n) {
                  const e = h.from - h.to,
                    i = h.anchor >= t && h.anchor <= o,
                    r = h.from >= t && h.to <= o,
                    c = 0 === e && i || r;
                  if (l.find(t, o, t => c === t.isEditing && n === t.content && f === t.isEditable && s === t.katexOptions).length) continue;
                  g.push(a.inline(t, o, {
                    class: c && f ? "Tiptap-mathematics-editor" : "Tiptap-mathematics-editor Tiptap-mathematics-editor--hidden",
                    style: c && f ? void 0 : "display: inline-block; height: 0; opacity: 0; overflow: hidden; position: absolute; width: 0;"
                  }, {
                    content: n,
                    isEditable: f,
                    isEditing: c,
                    katexOptions: s
                  })), f && c || g.push(a.widget(t, () => {
                    const t = document.createElement("span");
                    t.classList.add("Tiptap-mathematics-render"), f && t.classList.add("Tiptap-mathematics-render--editable");
                    try {
                      d.render(n, t, s);
                    } catch (e) {
                      t.innerHTML = n;
                    }
                    return t;
                  }, {
                    content: n,
                    isEditable: f,
                    isEditing: c,
                    katexOptions: s
                  }));
                }
              }
            }
          });
          const b = g.flatMap(t => l.find(t.from, t.to));
          return {
            decorations: l.remove(b).add(e.doc, g),
            isEditable: f
          };
        }
      },
      props: {
        decorations(t) {
          var e, i;
          return null !== (i = null === (e = this.getState(t)) || void 0 === e ? void 0 : e.decorations) && void 0 !== i ? i : n.empty;
        }
      }
    });
  },
  s = (t, e) => !("codeBlock" === t.doc.resolve(e).parent.type.name),
  c = e.create({
    name: "Mathematics",
    addOptions: () => ({
      regex: /\$([^\$]*)\$/gi,
      katexOptions: void 0,
      shouldRender: s
    }),
    addProseMirrorPlugins() {
      return [r({
        ...this.options,
        editor: this.editor
      })];
    }
  });
export { c as Mathematics, r as MathematicsPlugin, c as default, s as defaultShouldRender };